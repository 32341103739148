import React, { useEffect } from 'react';

import styled from '@emotion/styled';
import Image from 'next/image';

import { getEnvironmentValues } from '../../lib/config/Env';
import { isBrowser } from '../../lib/utils/isBrowser';

import CNFImage from './img.png';
import { styles } from './styles';

import type { GetServerSideProps } from 'next';

const Div = styled.div(styles);

const examples = {
  dev: {
    specialOffer: '',
    loans: 'results=8c959f74-342f-4f9e-8481-5d4f242fd115&step=results',
    path: '/partner/cnf-loans/loans',
  },
  prod: {
    specialOffer: 'results=77ae9b6d-8207-4274-97c7-5a1f0ea40a0c&step=results',
    path: '/partner/cnf-fiona/loans',
    loans: 'results=76b8c0ec-85e3-48f4-bc59-df2a39461ac1&step=results',
  },
};

export function CNFPage() {
  /*
   * Replace the elements below with your own.
   *
   * Note: The corresponding styles are in the ./index.css file.
   */
  const [state, setState] = React.useState('');
  useEffect(() => setState(JSON.stringify(getEnvironmentValues(), null, 2)), []);
  const env = isBrowser && getEnvironmentValues();
  const example = env.evenApiBase?.includes('dev') ? examples.dev : examples.prod;
  return (
    <Div>
      <div className="wrapper">
        <div className="containerDemo">
          <div id="middle-content">
            <div style={{ width: '100%' }}>
              <Image
                alt="CNF Development Index Page"
                height={142}
                src={CNFImage}
                style={{ maxWidth: '80%', objectFit: 'contain' }}
                width={640}
              />
              <p>Env Vars: </p>
              <p>{state}</p>
            </div>

            <div className="rounded shadow" id="learning-materials">
              <h2>Quick Links</h2>
              <a
                className="list-item-link"
                href={`${example.path}`}
                rel="noreferrer"
                target="_blank"
              >
                <svg
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </svg>
                <span>
                  Loan Search
                  <span> Prefilled Email </span>
                </span>
                <svg
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 5l7 7-7 7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </svg>
              </a>
              <a
                className="list-item-link"
                href={`${example.path}?${example.loans}`}
                rel="noreferrer"
                target="_blank"
              >
                <svg
                  fill="currentColor"
                  role="img"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>YouTube</title>
                  <path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z" />
                </svg>
                <span>
                  Loans Results
                  <span> Some Rate Table </span>
                </span>
                <svg
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 5l7 7-7 7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </svg>
              </a>
              <a
                className="list-item-link"
                href={`${example.path}?${example.specialOffer}`}
                rel="noreferrer"
                target="_blank"
              >
                <svg
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </svg>
                <span>
                  Special Offer
                  <span> Are you in good hands </span>
                </span>
                <svg
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 5l7 7-7 7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </svg>
              </a>
              <a
                className="list-item-link"
                href="/partner/cnf-loans/loans?results=77ae9b6d-8207-4274-97c7-5a1f0ea40a0c&step=results"
                rel="noreferrer"
                target="_blank"
              >
                <svg
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </svg>
                <span>
                  Special Offer Rate Table
                  <span>Doubles as static offer rate table </span>
                </span>
                <svg
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 5l7 7-7 7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </svg>
              </a>
              <a
                className="list-item-link"
                href={`${example.path}?step=offerList`}
                rel="noreferrer"
                target="_blank"
              >
                <svg
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 14l9-5-9-5-9 5 9 5z" />
                  <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                  <path
                    d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </svg>
                <span>
                  Offer List / Preview Step
                  <span> Explore, to those fiona users </span>
                </span>
                <svg
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 5l7 7-7 7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </svg>
              </a>
            </div>
          </div>

          <p id="love">Core Network Frontend</p>
        </div>
      </div>
    </Div>
  );
}

export const getServerSideProps: GetServerSideProps = async (_context) => {
  return {
    props: {},
  };
};
